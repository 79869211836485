<template>
    <section id="how-it-works" class="py-20 h-full bg-gradient-to-t from-primary-content/100 to-primary-content/0">
        <div class="flex flex-col items-center mx-6 mb-14 md:mx-14 lg:mb-[72px] xl:mb-24 2xl:mb-32 3xl:mx-72">
            <span
                class="text-sm font-semibold bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text mb-8">
                {{ $t('A SIMPLE SHARING PROCESS') }}
            </span>
            <div class="flex flex-col gap-6 text-center 2xl:mx-52">
                <span class="text-2xl lg:text-[32px] 2xl:text-[44px] leading-9 lg:leading-10 2xl:leading-[56px]">
                    {{ $t(' A smarter and reliable way to share your talents with more clients.') }}
                </span>

                <span class="text-xl">
                    {{
                        $t(
                            'Our system is simple yet powerful, leaving you time to dedicate on what most matter. Your relation with the client and the quality of your work and team.',
                        )
                    }}
                </span>
            </div>
            <div class="flex flex-col gap-2 mt-12 lg:hidden">
                <div
                    v-for="step in steps"
                    :key="step.position"
                    class="flex flex-col items-center gap-y-4 border rounded-[10px] px-6 py-4 border-base-200">
                    <div
                        class="md:w-fit h-fit rounded-full bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 p-[1px]">
                        <div class="flex justify-center items-center text-center bg-white rounded-full h-full">
                            <span
                                class="font-medium bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text px-4 py-1">
                                {{ $t('step :position', {position: step.position}) }}
                            </span>
                        </div>
                    </div>
                    <span class="text-center">
                        {{ step.description }}
                    </span>
                </div>
            </div>
            <ul class="hidden lg:steps xl:w-[98%] mt-[72px] w-full">
                <li
                    v-for="step in steps"
                    :key="step.position"
                    data-content=""
                    class="step before:bg-base-200 before:!h-[2px] after:!size-4 after:bg-gradient-to-r after:btn-sp-100">
                    <div
                        :class="`flex flex-col items-center w-60 xl:w-80 2xl:w-60 3xl:w-80 ${step.position === '1' ? `mt-1` : '-mt-4 xl:mt-0 2xl:-mt-4 3xl:mt-0'}`">
                        <div
                            class="rounded-full bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 p-[1px]">
                            <div class="flex justify-center items-center text-center bg-white rounded-full h-full">
                                <span
                                    class="font-medium bg-gradient-to-r from-sp-blue-100 via-sp-purple-100 to-sp-purple-200 inline-block text-transparent bg-clip-text px-4 py-1">
                                    {{ $t('step :position', {position: step.position}) }}
                                </span>
                            </div>
                        </div>
                        <span class="text-center mt-2">
                            {{ step.description }}
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>
<script setup lang="ts">
const steps = [
    {
        position: '1',
        description:
            'Create an account and share each one of your talent profiles with us, providing expertise level and cost per hour',
    },
    {
        position: '2',
        description:
            'We validate them and match their expertise with the best offers we have from all around the world',
    },
    {
        position: '3',
        description:
            'You get proposals and job opportunities on your inbox, carefully curated for your expertise and talents',
    },
];
</script>
